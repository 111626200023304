<template>
  <div class="md my-3">
    <div class="row my-2 py-2 mx-2 justify-content-center">
        <h1 class="text-center">Thank you!</h1>
        <p class="text-center">
          Thanks for subscribing!
          You may close this page.
        </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
  },
  created () {
    document.title = "Thanks for subscribing!"
  }
}
</script>

<style scoped>
.md {
  text-align: left;
  background-color: rgb(43, 43, 43);
  border-radius: 10px;
}
</style>