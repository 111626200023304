<template>
  <div class="my-3">
    <div class="row my-2 py-2 mx-2 justify-content-center">
      <h1>Courses</h1>
      <div class="album" data-bs-theme="dark">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-12"
                 v-for="course in courses"
                 :key="course"
                 >
              <div class="card mb-4 box-shadow">
                <img class="card-img-top" :src="course.image_location">
                <div class="card-body course-body">
                  <h2>{{course.title}}</h2>
                  <p class="card-text">{{ course.short_description }}</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <router-link :to="'/course/' + course.course_id">
                        <button type="button"
                                class="btn btn-outline-light"
                                >
                          Details
                        </button>
                      </router-link>
                    </div>
                    <small class="text-muted price">
                      {{ course.price == 0 ? 'Free' : '€ '+course.price}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import courses from '@/courses/courses.js'

  export default {
    data: function () {
      return {
        courses: courses
      }
    },
    methods: {
    },
    created () {
      document.title = "Courses"
    }
  }
</script>

<style scoped>
  .course-body {
    background-color: rgb(43, 43, 43);
    border-radius: 10px;
  }
  .price {
    font-size: 28px;
    font-family: "Open Sans", sans-serif;
  }
  p {
    text-align: left;
  }
  button {
    font-family: "Open Sans", sans-serif;
    background-color: rgb(43, 43, 43);
    font-size: 24px;
    color: #ddd;
  }
</style>
