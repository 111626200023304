<template>
  <div class="md mx-auto my-2">
    <div class="position-relative black-background rounded">
      <img :src="course.image_location" class="w-100 opaque" />
      <div class="position-absolute top-50 start-50 translate-middle text-center">
        <h1>{{course.title}}</h1>
      </div>
    </div>
    <div class="m-3 p-3">
      <p>{{course.short_description}}</p>
      <p>
        In this course, theory is mixed with practical exercises.
        Interactive questions test your understanding.
        You are encouraged to follow along with the examples given.
        We provide written and interactive course content instead of videos, so that you can go through the content at your own pace and can come back to relevant sections more easily.
        The course contents are listed below.
      </p>
      <div class="btn-group d-flex w-100">
        <div class="mb-3 mt-0 w-100">
          <router-link :to="'/course/' + course.course_id +'/waitlist'">
            <button type="button"
                    class="btn btn-outline-light w-100"
                    :disabled="is_redirecting">
              Buy (€ {{course.price}})
            </button>
          </router-link>
        </div>
      </div>
      <!--
      <div class="btn-group d-flex w-100">
        <div class="m-3 w-100">
          <router-link :to="'/course/' + course.course_id +'/page/0'">
            <button type="button"
                    class="btn btn-outline-light w-100">
              Demo
            </button>
          </router-link>
        </div>
      </div>
      -->
      <h3>Contents</h3>
      <ul>
        <li v-for="content in course.contents" :key="content">{{content}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
  import courses from '@/courses/courses.js'

  export default {
    data() {
      return {
        is_redirecting: false
      }
    },
    methods: {
      // TODO: bring back the buy function
    },
    created () {
      document.title = this.course.title
    },
    computed: {
      course() {
        var course_id = this.$route.params.course_id
        const course_regex = /^[a-z0-9-]+$/
        const matches = course_regex.exec(course_id)
        if (!matches) {
          // eslint-disable-next-line
          this.$router.push('/404')
          this.$forceUpdate()
          return
        }

        var returned_course = null
        courses.forEach(course => {
          if (course.course_id == course_id) {
            returned_course = course
          }
        })

        if (!returned_course) {
          // eslint-disable-next-line
          this.$router.push('/404');
          this.$forceUpdate()
          return
        }

        return returned_course
      }
    }
  }
</script>

<style scoped>
  .md {
    text-align: left;
    background-color: rgb(43, 43, 43);
    border-radius: 10px;
  }
  .opaque {
    opacity: .2;
  }
  .black-background {
    background-color: black;
  }
  .rounded {
    border-radius: 10px;
  }
  button {
    font-family: "Open Sans", sans-serif;
    background-color: rgb(43, 43, 43);
    font-size: 24px;
    color: #ddd;
  }
</style>
