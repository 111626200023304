<template>
  <h1 class="mt-3">Not Found</h1>
</template>

<script>
export default {
  created() {
    document.title = "Not found"
  }
}
</script>