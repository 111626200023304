import { createRouter, createWebHistory } from 'vue-router'
import BlogPage from '@/blog/BlogPage'
import BlogPost from '@/blog/BlogPost'
import SubscribePage from '@/blog/SubscribePage'
import ProjectsPage from '@/portfolio/ProjectsPage'
import AboutPage from '@/portfolio/AboutPage'
import CoursesPage from '@/courses/CoursesPage'
import CourseDetailsPage from '@/courses/CourseDetailsPage'
import WaitlistPage from '@/courses/WaitlistPage'
import SubscriptionConfirm from '@/generic/SubscriptionConfirm'
import SubscriptionThankYou from '@/generic/SubscriptionThankYou'
import NotFound from '@/generic/NotFound'
import PrivacyPolicy from '@/generic/PrivacyPolicy'
import TermsOfUse from '@/generic/TermsOfUse'
import HomePage from '@/generic/HomePage'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/blog',
      name: 'Blog',
      component: BlogPage
    },
    {
      path: '/blog/:filename',
      name: 'Blogpost',
      component: BlogPost
    },
    {
      path: '/subscribe',
      name: 'SubscribePage',
      component: SubscribePage
    },
    {
      path: '/projects',
      name: 'Projects',
      component: ProjectsPage
    },
    {
      path: '/about',
      name: 'About',
      component: AboutPage
    },
    {
      path: '/courses',
      name: 'CoursesPage',
      component: CoursesPage
    },
    {
      path: '/course/:course_id',
      name: 'CourseDetailsPage',
      component: CourseDetailsPage
    },
    {
      path: '/course/:course_id/waitlist',
      name: 'WaitlistPage',
      component: WaitlistPage
    },
    {
      path: '/confirm-your-subscription',
      name: 'SubscriptionConfirm',
      component: SubscriptionConfirm
    },
    {
      path: '/thanks-for-subscribing',
      name: 'SubscriptionThankYou',
      component: SubscriptionThankYou
    },
    {
      path: '/terms',
      name: 'TermsOfUse',
      component: TermsOfUse
    },
    {
      path: '/privacy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: NotFound
    }
  ]
})
export default router
