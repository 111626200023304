<template>
  <div>
    <iframe width="540" height="700" src="https://db391e00.sibforms.com/serve/MUIFACUNWCJxyX383varP0YQD9efkSnKsBsUe-OKhwUh9EHxnQMBBp3N5rnwqbaNTx4wsGmT85MPLlAYcN3XunazvVgrQMyNBr57_Woq83ycn0y5smbS6ct_LqF_YmokWQmAKMKjvQKRI3QjUbZznoKt7kM7Cm3nNLagQBxA-cha47o9olHHbD4glq9bIs_AQ8loYH2Ssmn8YJbu" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
  </div>
</template>

<script>
export default {
  created () {
    document.title = "Register for the waitlist"
  }
}
</script>

<style scoped>

</style>