<template>
  <div class="md my-3 p-3">
    <span v-html="post"></span>
  </div>
</template>

<script>
  import MarkdownIt from 'markdown-it'

  function display_post() {
    var md = new MarkdownIt()
    fetch('../privacy.md')
      .then(response => response.text())
      .then(text => {
        this.post = md.render(text);
      })
  }

  export default {
    data() {
      return {
        post: 'Loading...',
      }
    },
    created () {
      document.title = "Privacy Policy"
    },
    updated: display_post,
    mounted: display_post  // TODO updating does not seem to work
  }
</script>

<style scoped>
  .md {
    text-align: left;
    background-color: rgb(43, 43, 43);
    border-radius: 10px;
  }

  span >>> p {
    font-family: "Roboto", sans-serif;
  }

  span >>> li {
    font-family: "Roboto", sans-serif;
  }

  span >>> em {
    font-family: "Roboto", sans-serif;
  }

  span >>> h1 {
    font-family: "Open Sans", sans-serif;
  }

  span >>> h2 {
    font-family: "Open Sans", sans-serif;
  }

  span >>> h3 {
    font-family: "Open Sans", sans-serif;
  }

  span >>> h4 {
    font-family: "Open Sans", sans-serif;
  }

  span >>> h5 {
    font-family: "Open Sans", sans-serif;
  }

  span >>> h6 {
    font-family: "Open Sans", sans-serif;
  }

  span >>> pre {
    background-color: rgb(56, 58, 60);
  }
</style>