<template>
  <div>
    <iframe width="540" height="700" src="https://db391e00.sibforms.com/serve/MUIFAGQ3wfeAAkUhT5KjVJeIzAufXl_Uod5CG18g793hcetDpzVuyOcMJw0c0tMvAYlRwB5OjhSuZy5upXUV0Jt27AFayW0wzPjiRUwkOc1F_0ZAgMeawKNUp0k3oQYNnMtkZpGobtvtxGaktF6nOzvSQQxVtN-MsV4oVp4SiDQf6zAjmQinG68Q3TwS00BmaK1DN59bNjzZTKYR" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
  </div>
</template>

<script>
export default {
  created () {
    document.title = "Subscribe"
  }
}
</script>

<style scoped>

</style>