<template>
  <div id="app">
    <NavBar />
    <div class="container-fluid">
      <div class="row">
        <div class="col col-0 col-sm-1 col-lg-3"></div>
        <div class="col col-12 col-sm-10 col-lg-6">
          <router-view />
        </div>
        <div class="col col-0 col-sm-1 col-lg-3"></div>
      </div>
    </div>
    <footer class="mt-5 mb-2 text-center">
      <div class="container text-center">
        <a class="small-link" href="/terms">Terms of Use</a>
        <span>&nbsp;</span>
        <a class="small-link" href="/privacy">Privacy Policy</a>
      </div>
    </footer>
    <div v-if="cookie_consent == null"
         class="fixed-bottom p-3 cookie-banner"
         data-bs-theme="dark"
         >
      <div class="d-flex justify-content-center align-items-center">
        <p class="mb-0 text-center"
           style="font-size: 24px;">
          We use cookies to improve our website. Please accept or decline cookies.
        </p>
        <div class="ms-3">
          <button class="btn btn-success btn-lg m-1"
                  @click="save_consent(true)"
                  data-bs-theme="dark">Accept</button>
          <button class="btn btn-danger btn-lg m-1"
                  @click="save_consent(false)"
                  data-bs-theme="dark">Decline</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavBar from './generic/NavBar.vue';

  export default {
    name: 'App',
    data() {
      return {
        cookie_consent: null
      }
    },
    methods: {
      save_consent(accepted) {
        localStorage.setItem(
          "cookie_consent",
          JSON.stringify(accepted)
        );
        this.cookie_consent = accepted;

        if (accepted) {
          this.load_analytics();
        }
      },
      load_analytics() {
         this.cookie_consent = JSON.parse(
           localStorage.getItem("cookie_consent")
         )
         if (!(this.cookie_consent === true)) {
           console.debug('Cookie consent not given or rejected.')
           return
         }

         console.debug('Cookie consent given. Enabling Google Analytics.')
         this.load_async_script(
           'https://www.googletagmanager.com/gtag/js?id=G-K49GTNVEK6'
         ).then(() => {
           // eslint-disable-next-line
           window.dataLayer = window.dataLayer || [];
           // eslint-disable-next-line
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());

           gtag('config', 'G-K49GTNVEK6');
         })
      },
      load_async_script(src) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.src = src
          script.async = true
          script.onload = () => resolve()
          script.onerror = () => reject(new Error(`Failed to load script: ${src}`))
          document.head.appendChild(script)
        })
      }
    },
    mounted() {
      this.load_analytics()
    },
    components: {
       NavBar
    }
  }
</script>

<style>
  @import 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap';
  @import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap';

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ddd;
    background-color: rgb(60, 63, 65);
  }

  html, body {
    background-color: rgb(60, 63, 65);
  }

  p {
    font-family: "Roboto", serif;
  }
  li, ul {
    font-family: "Roboto", serif;
  }
  h1 {
    font-family: "Open Sans", sans-serif;
  }
  h2 {
    font-family: "Open Sans", sans-serif;
  }
  h3 {
    font-family: "Open Sans", sans-serif;
  }
  h4 {
    font-family: "Open Sans", sans-serif;
  }
  h5 {
    font-family: "Open Sans", sans-serif;
  }
  h6 {
    font-family: "Open Sans", sans-serif;
  }

  .left-aligned-col {
    text-align: left;
  }

  .centered-col {
    text-align: center;
  }

  .centered-span {
    font-size: 20pt;
    vertical-align: middle;
  }

  .settings-box {
    background-color:rgb(43, 43, 43);
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .cookie-banner {
    background-color:rgb(43, 43, 43);
  }

  .small-link {
    font-size: 12px;
    color: #bbb;
  }
  .small-link:hover {
    color: #eee;
  }
</style>
