<template>
  <div class="md my-3">
    <div class="row my-2 py-2 mx-2 justify-content-center">
        <h1 class="title text-center">vangemert.dev</h1>
        <div class="col col-12 col-lg-7">
            <p>{{short_description}}</p>
        </div>
        <div class="col col-5 col-lg-3">
            <img src="images/aws-certified-solutions-architect.png">
        </div>
        <div class="col col-5">
            <img src="images/steven_van_gemert.png">
        </div>
    </div>
  </div>
</template>

<script>
import short_description from '@/portfolio/description.js'

export default {
  data() {
    return {
      short_description: short_description
    }
  },
  created () {
    // keep old single page application links backwards compatible
    const fragment = this.$route.hash.substring(1)
    const fragment_regex = /^\/blog\/[a-zA-Z0-9-]+$/
    const matches = fragment_regex.exec(fragment)
    if (matches) {
      console.debug('Forwarding to blogpost to keep old style single page application links working')
      this.$router.replace(fragment)
      return
    }
    console.debug('Fragment not forwarded:', fragment)

    document.title = "Home"
  }
}
</script>

<style scoped>
.md {
  text-align: left;
  background-color: rgb(43, 43, 43);
  border-radius: 10px;
}
.title {
  font-family: "Courier New", Courier, monospace;
}
img {
  width: 100%;
}
</style>