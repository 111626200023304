<template>
  <div class="md my-3">
    <div class="row my-2 py-2 mx-2 justify-content-center">
        <h1 class="text-center">Confirm your subscription</h1>
        <p>
          We need to confirm your email address to complete the subscription process.
          Please click on the link present in the email we just sent you.
          You won't be subscribed if you don't click the confirmation link.
        </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
  },
  created () {
    document.title = "Confirm your subscription"
  }
}
</script>

<style scoped>
.md {
  text-align: left;
  background-color: rgb(43, 43, 43);
  border-radius: 10px;
}
</style>